import React, { useState } from "react";
import "./AddNewCompany.css";
import Popup from "../../../Components/Popups/Popup";
import { Box, Button } from "@mui/material";
import { URLs } from "../../../api/URLs";
import axios from "../../../api/axios";
import ApiResult from "../../Widgets/ApiResult";
import Loading from "../../Widgets/Loading";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { setCompanies } from "../../../state";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShortNote from "../../../Components/ShortNote/ShortNote";
import CustomTextfield from "../../Widgets/CustomTextfield";
import { Image } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

dayjs.extend(utc);

function AddNewCompany({ closePopup, onSuccess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [tin, settin] = useState("");
  const [branch_name, setBranchName] = useState("");
  const [branch_address, setBranchAddress] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const [opening_hour, setOpeningHour] = useState(dayjs());
  const [closing_hour, setClosingingHour] = useState(dayjs());
  const [fieldValues, setFieldValues] = useState(null);
  const [preview, setPreview] = useState("");

  const handleImageChnange = (e) => {
    console.log(e);
    if (e.target.files.length) {
      setPreview(e.target.files[0]);
      setFieldValues(URL.createObjectURL(e.target.files[0]));
    } else {
      setPreview("");
      setFieldValues(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const open = opening_hour.unix();
      const close = closing_hour.unix();
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", preview);
      formData.append("upload_preset", "ffblgmbh");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dsskh3fao/image/upload",
          formData
        )
        .then(async (cld_response) => {
          const image = cld_response.data.url;
          console.log(cld_response);
          const response = await axios.post(
            URLs.ADD_NEW_COMPANY,
            JSON.stringify({
              name,
              tin,
              open,
              close,
              image,
              branch_address,
              branch_name,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          );

          const companies = response?.data?.msg;
          console.log(response);
          dispatch(
            setCompanies({
              companies: [...companies],
            })
          );
          setName("");
          settin("");

          setIsLoading(false);
          closePopup(false);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log(err);
          if (!err?.response) {
            setErrorMsg("No Server Response");
          } else if (
            err.response?.status === 409 ||
            err.response?.status === 401
          ) {
            setErrorMsg(err.response?.data.msg);
          } else {
            setErrorMsg("Add Company Failed");
          }
          setIsError(true);
        });
    } catch (err) {
      setIsLoading(false);

      console.log(err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Add Company Failed");
      }
      setIsError(true);
    }
  };
  const handleCloseErrorMsg = () => {
    setIsError(false);
  };
  return (
    <div className="add-new-company">
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={handleCloseErrorMsg}
          isSuccess={false}
        ></ApiResult>
      )}
      <Popup
        title={"Add New Company"}
        // onClick={()=>{}}
        // buttonChild={"Add"}
        // closePopup={closePopup}
      >
        <form className="add-new-company-form" onSubmit={handleSubmit}>
          <div className="form-body">
            <CustomTextfield
              label={"Name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
            />
            <CustomTextfield
              label={"Tin"}
              value={tin}
              onChange={(e) => settin(e.target.value)}
              required={true}
            />

            <div className="text-field">
              <label htmlFor="opening">Opening Hour</label>

              <TimePicker
                id="opening"
                // label="Uncontrolled picker"
                onChange={(e) => setOpeningHour(e)}
                defaultValue={opening_hour}
                height={"100px"}
                sx={{
                  // "&:hover": { cursor: "pointer" }
                  "*": { borderRadius: ".7rem" },
                  backgroundColor: "white",
                  borderRadius: ".7rem",
                }}
              />
            </div>
            <div className="text-field">
              <label htmlFor="closing">Closing Hour</label>

              <TimePicker
                id="closing"
                onChange={(e) => setClosingingHour(e)}
                defaultValue={closing_hour}
                borderRadius={".7rem"}
                sx={{
                  // "&:hover": { cursor: "pointer" }
                  "*": { borderRadius: ".7rem" },
                  backgroundColor: "white",
                  borderRadius: ".7rem",
                }}
              />
            </div>
            <div className="span-2">
              {/* <Dropzone
              acceptedFiles=".jpg, .jpeg, .png"
              maxSize={0.5}
              multiple={false}
                onDrop={(acceptedFiles)=>setFieldValues(acceptedFiles[0])}
            >
              {({ getRootProps, getInputProps }) => ( */}
              <Box
                 display={"flex"}
                 justifyContent={"space-between"}
                 alignItems={"center"}
                 gap={"1rem"}
                 border={`2px dashed lightblue`}
                 borderRadius={".5rem"}
                 p="1rem"
                 sx={{
                   // "&:hover": { cursor: "pointer" }
                   backgroundColor: "white",
                   minHeight: 100,
                 }}
              >
                <label for="file-upload" class="custom-file-upload">
                  {fieldValues ? "Change Image" : "Upload Image"}
                </label>
                <input
                  id="file-upload"
                  height={""}
                  type="file"
                  onChange={handleImageChnange}
                  accept="image/png, image/jpeg, image/jpg"
                  // value={preview.name}
                />
                {/* {value}
                 */}
                <div>
                {!fieldValues && (
                  <Image
                    placeholder={blue}
                    sx={{
                      height: 100,
                      width: 150,
                      color: blue,
                    }}
                  />
                )}
                  {fieldValues && (
                    <img
                      width={"140px"}
                      // height={"100px"}
                      src={fieldValues && fieldValues}
                      alt="priview"
                    />
                  )}
                </div>
              </Box>
              {/* )}
            </Dropzone> */}
            </div>
            <div className="span-2">
              <ShortNote
                text={
                  "To add a company, one branch is the minimum. So please enter branch information below."
                }
              />
            </div>
            <CustomTextfield
              label={"Branch Name"}
              value={branch_name}
              onChange={(e) => setBranchName(e.target.value)}
              required={true}
            />
            <CustomTextfield
              label={"Branch Address"}
              value={branch_address}
              onChange={(e) => setBranchAddress(e.target.value)}
              required={true}
            />
          </div>
          <div className="footer">
            <Button
              type="submit"
              disabled={
                name.trim() == "" ||
                tin.trim() == "" ||
                branch_address.trim() == "" ||
                branch_name.trim() == "" ||
                !fieldValues
              }
            >
              Add
            </Button>
            <Button onClick={() => closePopup(true)}>
              <p>Close</p>
            </Button>
          </div>
        </form>
      </Popup>
    </div>
  );
}

export default AddNewCompany;
