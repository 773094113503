import React, { useState, useContext, useEffect } from "react";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLogout } from "../../../state";
import { useNavigate } from "react-router-dom";
import { getRole } from "../../../Constants/DataaseValues";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import EditUserProfile from "./EditUserProfile";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function AccountHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const onClickHandler = async () => {
    dispatch(setLogout());
    navigate("/");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClicked = (x) => {
    console.log(x.currentTarget.id);
  };
  const [isEditUserProfilVisible, setIsEditProfilVisible] = useState(false);
  return (
    <>
      {isEditUserProfilVisible ? (
        <EditUserProfile closePopup={() => setIsEditProfilVisible(false)} />
      ) : null}
      <hr className="twoOnePadding" />

      <div className="account-bar">
        <div className="account-header">
          <div className="avatar">
            {" "}
            <img className="avater-img" src={user?.avatar} />
          </div>
          <div className="name-email">
            <h4 className="name">
              {" "}
              {user?.first_name} {user?.last_name}
            </h4>
            <p className="name"> {getRole[user.role]}</p>
            {/* <p className="logout-btn" margin={"35px"} onClick={onClickHandler}>
              Logout
            </p> */}

            {/* <p className='name'> { user?.role}</p> */}
          </div>
          <div>
            <MoreVertIcon
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              style={{ cursor: "pointer" }}
            >
              {/* Options */}
            </MoreVertIcon>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setIsEditProfilVisible(true);
                  handleClose();
                }}
                disableRipple
              >
                {/* <EditIcon /> */}
                Change Password
              </MenuItem>
              <MenuItem
                disableRipple
                style={{ color: "red" }}
                onClick={() => {
                  handleClose();

                  onClickHandler();
                }}
              >
                {/* <FileCopyIcon /> */}
                Log-out
              </MenuItem>
              {/* <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={handleClose} disableRipple>
                      <ArchiveIcon />
                      Archive
                    </MenuItem>
                    <MenuItem onClick={handleClose} disableRipple>
                      <MoreHorizIcon />
                      More
                    </MenuItem> */}
            </StyledMenu>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountHeader;
