import { Button } from "@mui/material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ListShorter from "../Components/ListShorter/ListShorter";
export const Companies = (companies, buttonFunctions) => {
  var companies_model = [];
  if (companies) {
    companies.map((e) => {
      companies_model.push({
        id: companies.indexOf(e) + 1,
        Image: e.image,
        // (
        //   <img
        //     className="table-image"
        //     //   src={e.image}
        //     src={
        //       String(e.image) != ""
        //         ? e.image
        //         : "https://res.cloudinary.com/dhe0yelid/image/upload/v1692110703/anbessa_8e7b499497.png"
        //     }
        //     alt=""
        //   />
        // ),
        name: e.name,
        tin: e.tin,
        // opening: e.opening_hour,
        branches:e,// e.branches,
        closing: `${e.opening_hour} - ${e.closing_hour}`,
        action: e.id,
        // [
        //   <Button
        //     style={{
        //       border: "2px solid rgb(62, 62, 216)",
        //       color: "rgb(62, 62, 216)",
        //       marginRight: "10px",
        //       boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
        //       padding: "0",
        //       width: "10px",
        //     }}
        //     onClick={() => {
        //       buttonFunctions[1](e.id);
        //       buttonFunctions[0]();
        //     }}
        //   >
        //     <EditNoteOutlinedIcon />
        //   </Button>,
        //   <Button
        //     style={{
        //       border: "2px solid coral",
        //       color: "coral",
        //       boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
        //       padding: "0",
        //       width: "10px !important",
        //     }}
        //     onClick={() => {
        //       buttonFunctions[3](e.id);
        //       buttonFunctions[2]();
        //     }}
        //   >
        //     <ClearOutlinedIcon />
        //   </Button>,
        // ],
      });
    });
  }

  const prop_table = {
    header: [
      {
        field: "id",
        headerName: "NO.",
        width: 300,
        headerClassName: "super-app-theme--header",
        minWidth: 75,
        align: "right",
        headerAlign: "right",
        flex: 0.7,
      },

      {
        field: "Image",
        headerName: " ",
        headerClassName: "super-app-theme--header",
       width:150,

        align: "center",
        padding: "10px",
        headerAlign: "center",
        renderCell: (params) => (
          <img
            className="table-image"
            //   src={e.image}
            src={
              String(params.value) != ""
                ? params.value
                : "https://res.cloudinary.com/dhe0yelid/image/upload/v1692110703/anbessa_8e7b499497.png"
            }
            alt=""
          />
        ),
      },
      {
        field: "name",
        headerName: "COMPANY NAME",
        minWidth: 300,
        headerClassName: "super-app-theme--header",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "tin",
        headerName: "TIN",
        width: 150,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "branches",
        headerName: "BRANCHES",
        width: 150,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <>
            {/* <ul>
              {params.value.map(e=> <li key={e.id}>
                 <a> {e.name}</a>
              </li>)}
            </ul> */}

            {params.value&&
              <ListShorter list = {params.value.branches} company={params.value.id} show = {1}/>}
          </>
        ),
      },
      // {
      //   field: "opening",
      //   headerName: "OPENING HOUR",
      //   width: 150,
      //   headerClassName: "super-app-theme--header",
      // },
      {
        field: "closing",
        headerName: "CLOSING - OPENING",
        width: 150,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 250,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <>
            <Button
              style={{
                // border: "2px solid rgb(62, 62, 216)",
                color: "rgb(62, 62, 216)",
                marginRight: "10px",
                boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",
                padding: "0",
                width: "10px",
              }}
              onClick={() => {
                buttonFunctions[1](params.value);
                buttonFunctions[0]();
              }}
            >
              <EditNoteOutlinedIcon />
            </Button>
            ,
            <Button
              style={{
                // border: "2px solid coral",
                color: "coral",
                // boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
                boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

                padding: "0",
                width: "10px !important",
              }}
              onClick={() => {
                buttonFunctions[3](params.value);
                buttonFunctions[2]();
              }}
            >
              <ClearOutlinedIcon />
            </Button>
          </>
        ),
      },
    ],
    // header: [
    //   " ",
    //   "COMPANY NAME",
    //   "TIN",
    //   "OPENING HOUR",
    //   "CLOSING HOUR",
    //   "ACTION",
    // ],
    data: [...companies_model],
  };
  return prop_table;
};
