import { createSlice } from "@reduxjs/toolkit";
// rxslice
const initialState = {
  user: null,
  token: null,
  companies: [],
  branches: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.companies;
    },
    setBranches: (state, action) => {
      state.branches = action.payload.branches;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setLogin, setLogout, setCompanies, setBranches } =
  authSlice.actions;
export default authSlice.reducer;
