import React, { useEffect, useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import ShortNote from "../../../Components/ShortNote/ShortNote";
import {
  add_new_users,
  check_username,
  fetch_companies,
  fetch_company_branches,
} from "../../../Services/Admin/admin_services";
import { useSelector } from "react-redux";
import CustomForm from "../../../Components/CustomForm/CustomForm.jsx";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomSelector from "../../Widgets/CustomSelector";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { AccountCircle, CheckCircleOutline } from "@mui/icons-material";
function AddNewUser({ closePopup }) {
  const token = useSelector((state) => state.token);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [role, setRole] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);

  // DEBOUCE STUFF
  // const useSearchStarwarsHero = () =>
  //   useDebouncedSearch((text) => handleCheckUsername(username));

  // const { inputText, setInputText, searchResults } = useSearchStarwarsHero();

  // STARTUPS AND FETCH COMPANIES
  useEffect(() => {
    getCompanies();
  }, []);
  const getCompanies = async () => {
    try {
      const companiesData = await fetch_companies(token);
      setCompanies(companiesData);
    } catch (error) {
      setErrorMsg("Couldn't load companies, try again.");
      setIsError(true);
    }
  };

  // FETCH BRANCHES
  const getBranches = async () => {
    try {
      setBranches([]);
      setBranchId("");
      if (companyId !== 1 && role == 2) setRole(1);
      if (companyId && companyId != "") {
        const branchesData = await fetch_company_branches(token, companyId);
        setBranches(branchesData);
        if (branchesData.length) {
          setBranchId(branchesData[0].id);
        }
      }
    } catch (error) {
      setErrorMsg("Couldn't load company branches, try again.");
      setIsError(true);
    }
  };
  useEffect(() => {
    getBranches();
  }, [companyId]);
  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const param = {
        first_name: firstName,
        last_name: lastName,
        username: username,
        phone: phone,
        branch_id: branchId,
        company_id: companyId,
        role: role,
        password: "12345678",
      };
      const res = await add_new_users(token, param);
      setIsLoading(false);
      closePopup(false);
    } catch (error) {
      setIsLoading(false);

      setErrorMsg("Couldn't add user, try again.");
      setIsError(true);
    }
  };

  // CHECK USERNAME

  const handleCheckUsername = async (username) => {
    // setIsUsernameAvailable(null);
    try {
      setTimeout(async () => {
        const usernameAvailablity = await check_username(token, username);
        setIsUsernameAvailable(usernameAvailablity);
      }, 2000);
    } catch (error) {
      setIsLoading(false);

      setErrorMsg("Couldn't check username availiblity, try again.");
      setIsError(true);
    }
  };

  // DEBOUNC
  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup title={"Add New User"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          style={{ width: "100% !important" }}
          width={"100%"}
          // style={{ width:" 100%" }}
          button={
            <div>
              <Button
                type="submit"
                disabled={
                  String(firstName).trim() == "" ||
                  String(lastName).trim() == "" ||
                  String(username).trim() == "" ||
                  String(phone).trim() == "" ||
                  String(companyId).trim() == "" ||
                  String(companyId).trim() == "0" ||
                  String(branchId).trim() == "0" ||
                  String(branchId).trim() == "" ||
                  String(role).trim() == "" ||
                  role > 2 ||
                  role < 0 ||
                  !isUsernameAvailable
                  //  ||
                  //   tin.trim() ==""
                  //   ||
                  //   branch_address.trim() ==""
                  //   ||
                  //   branch_name.trim() ==""
                  //   ||
                  //   !fieldValues
                }
              >
                <p>Add</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
          {
            <>
              <CustomTextfield
                label={"First Name"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required={true}
              />
              <CustomTextfield
                label={"Last Name"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required={true}
              />

              <CustomTextfield
                label={
                  isUsernameAvailable == null
                    ? "Username"
                    : isUsernameAvailable == false
                    ? "Username taken"
                    : "Username available"
                }
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setIsUsernameAvailable(null);
                  if (String(e.target.value).trim())
                    handleCheckUsername(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isUsernameAvailable == null ? (
                        // "@"
                        <AccountCircle />
                      ) : isUsernameAvailable == false ? (
                        <ReportProblemIcon />
                      ) : (
                        <CheckCircleOutline />
                      )}
                    </InputAdornment>
                  ),
                }}
                required={true}
              />

              <CustomTextfield
                label={"Phone-number"}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required={true}
              />
              <div className="text-field span-2">
                <InputLabel id="combo-box-demo">Company</InputLabel>

                <Autocomplete
                  // disablePortal
                  required={true}
                  borderRadius={".7rem"}
                  sx={{
                    // "&:hover": { cursor: "pointer" }
                    "*": { borderRadius: ".7rem" },
                    backgroundColor: "white",
                    borderRadius: ".7rem",
                  }}
                  id="combo-box-demo"
                  options={companies}
                  getOptionLabel={(option) => option.name}
                  // sx={{ width: 300 }}
                  disabled={companies.length ? false : true}
                  // label="Company"
                  onChange={(e, item) => {
                    if (item) {
                      setCompanyId(item.id);
                    } else {
                      setCompanyId("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //  label="Company"
                    />
                  )}
                />
              </div>

              <CustomSelector
                required={true}
                label={"Branch"}
                value={branchId}
                onChange={(e) => {
                  setBranchId(e.target.value);
                }}
                menuItems={branches.map((e) => ({ id: e.id, name: e.name }))}
              />
              <CustomSelector
                required={true}
                label={"Role"}
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                menuItems={[
                  {
                    name: "Admin",
                    id: 2,
                    disabled: companyId !== 1 ? true : false,
                  },
                  { name: "Manager", id: 1 },
                  { name: "Cashier", id: 0 },
                ]}
              />

              <div className="span-2">
                {/* <ShortNote
                  text={
                    "To add a company, one branch is the minimum. So please enter branch information below."
                  }
                /> */}
              </div>
            </>
          }
        </CustomForm>
      </Popup>
    </div>
  );
}

export default AddNewUser;

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
  {
    label: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { label: "The Good, the Bad and the Ugly", year: 1966 },
  { label: "Fight Club", year: 1999 },
  {
    label: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    label: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { label: "Forrest Gump", year: 1994 },
  { label: "Inception", year: 2010 },
  {
    label: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: "Goodfellas", year: 1990 },
  { label: "The Matrix", year: 1999 },
  { label: "Seven Samurai", year: 1954 },
  {
    label: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { label: "City of God", year: 2002 },
  { label: "Se7en", year: 1995 },
  { label: "The Silence of the Lambs", year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: "Life Is Beautiful", year: 1997 },
  { label: "The Usual Suspects", year: 1995 },
  { label: "Léon: The Professional", year: 1994 },
  { label: "Spirited Away", year: 2001 },
  { label: "Saving Private Ryan", year: 1998 },
  { label: "Once Upon a Time in the West", year: 1968 },
  { label: "American History X", year: 1998 },
  { label: "Interstellar", year: 2014 },
  { label: "Casablanca", year: 1942 },
  { label: "City Lights", year: 1931 },
  { label: "Psycho", year: 1960 },
  { label: "The Green Mile", year: 1999 },
  { label: "The Intouchables", year: 2011 },
  { label: "Modern Times", year: 1936 },
  { label: "Raiders of the Lost Ark", year: 1981 },
  { label: "Rear Window", year: 1954 },
  { label: "The Pianist", year: 2002 },
  { label: "The Departed", year: 2006 },
  { label: "Terminator 2: Judgment Day", year: 1991 },
  { label: "Back to the Future", year: 1985 },
  { label: "Whiplash", year: 2014 },
  { label: "Gladiator", year: 2000 },
  { label: "Memento", year: 2000 },
  { label: "The Prestige", year: 2006 },
  { label: "The Lion King", year: 1994 },
  { label: "Apocalypse Now", year: 1979 },
  { label: "Alien", year: 1979 },
  { label: "Sunset Boulevard", year: 1950 },
  {
    label:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { label: "The Great Dictator", year: 1940 },
  { label: "Cinema Paradiso", year: 1988 },
  { label: "The Lives of Others", year: 2006 },
  { label: "Grave of the Fireflies", year: 1988 },
  { label: "Paths of Glory", year: 1957 },
  { label: "Django Unchained", year: 2012 },
  { label: "The Shining", year: 1980 },
  { label: "WALL·E", year: 2008 },
  { label: "American Beauty", year: 1999 },
  { label: "The Dark Knight Rises", year: 2012 },
  { label: "Princess Mononoke", year: 1997 },
  { label: "Aliens", year: 1986 },
  { label: "Oldboy", year: 2003 },
  { label: "Once Upon a Time in America", year: 1984 },
  { label: "Witness for the Prosecution", year: 1957 },
  { label: "Das Boot", year: 1981 },
  { label: "Citizen Kane", year: 1941 },
  { label: "North by Northwest", year: 1959 },
  { label: "Vertigo", year: 1958 },
  {
    label: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { label: "Reservoir Dogs", year: 1992 },
  { label: "Braveheart", year: 1995 },
  { label: "M", year: 1931 },
  { label: "Requiem for a Dream", year: 2000 },
  { label: "Amélie", year: 2001 },
  { label: "A Clockwork Orange", year: 1971 },
  { label: "Like Stars on Earth", year: 2007 },
  { label: "Taxi Driver", year: 1976 },
  { label: "Lawrence of Arabia", year: 1962 },
  { label: "Double Indemnity", year: 1944 },
  {
    label: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { label: "Amadeus", year: 1984 },
  { label: "To Kill a Mockingbird", year: 1962 },
  { label: "Toy Story 3", year: 2010 },
  { label: "Logan", year: 2017 },
  { label: "Full Metal Jacket", year: 1987 },
  { label: "Dangal", year: 2016 },
  { label: "The Sting", year: 1973 },
  { label: "2001: A Space Odyssey", year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];
