export const getRole = {
    0:"Cashier",
   1:"Manager",
    2:"Admin"

}
export const getStatus = {
    0:"Suspended",
    1:"Active"
}
export const getOrderStatus = {
    0:"wew"
}
export const orderMethod = {
    2:"Drive Up",
    3:"From the table",
    4:"From room"
}
export const paymentMethod = {
    0:"Cash",
    1:"Chapa"
}
export const fakedata = [
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
    {
        blog_name:"ahsdkfjashdfkj",
        blog_auther:"kajhskdjfh",
        hfajksdf:"adjshf"
    },
]