import { URLs } from "../../api/URLs";
import axios from "../../api/axios";

export const fetch_orders = async (token) => {
    const response = await axios
      .get(URLs.FETCH_ORDERS, {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res?.data, "orders");
       return res?.data
      })
      .catch((error) => {
        console.log(error, "error getting orders");
        throw error;
      });
    return response;
  };
export const updateOrderStatus = async (token, id, status) => {
    const response = await axios
      .post(URLs.UPDATE_ORDER[status], JSON.stringify({
        id:id
      }),{
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => {
       return res?.data
      })
      .catch((error) => {
        console.log(error, "error moving orders to kitchen");
        throw error;
      });
    return response;
  };
export const move_order_to_completed = async (token, id) => {
    const response = await axios
      .post(URLs.MOVE_TO_COMPLETED, JSON.stringify({
        id:id
      }),{
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res?.data, "orders");
       return res?.data
      })
      .catch((error) => {
        console.log(error, "error moving orders to completed");
        throw error;
      });
    return response;
  };
export const move_order_to_canceled = async (token, id) => {
    const response = await axios
      .post(URLs.MOVE_TO_CANCELED, JSON.stringify({
        id:id
      }),{
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res?.data, "orders");
       return res?.data
      })
      .catch((error) => {
        console.log(error, "error moving orders to canceled");
        throw error;
      });
    return response;
  };
export const clearCanceledOrders = async (token) => {
    const response = await axios
      .delete(URLs.CLEAR_CANCELED_ORDERS, {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => { 
       return res?.data
      })
      .catch((error) => {
        console.log(error, "error clearing orders");
        throw error;
      });
    return response;
  };


  export const fetch_reservations = async (token) => {
    const response = await axios
      .get(URLs.FETCH_RESERVATION, { 
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res?.data, "reservation");
       return res?.data?.msg
      })
      .catch((error) => {
        console.log(error, "error getting orders");
        throw error;
      });
    return response;
  };
