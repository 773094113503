import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { URLs } from "../../api/URLs";
import { useDispatch, useSelector } from "react-redux";
import { setBranches, setLogin } from "../../state";

function Preloader({ setIsLoaded }) {
  //   const [isLoaded, setIsLoaded] = useState();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);

  const loadState = async () => {
    await axios
      .get(URLs.PRELOAD_STATE, {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res?.data, "preloading");

        if (res?.data?.user.role == 2) {
          dispatch(
            setLogin({
              user: res?.data?.user,
              token: res?.data?.token,
            })
          );
        } else if (res?.data?.user.role == 1) {
          dispatch(
            setLogin({
              user: res?.data?.user,
              token: res?.data?.token,
            })
          );
          dispatch(
            setBranches({
              branches: res?.data?.branches,
            })
          );
        } else {
        }

        setIsLoaded();
      })
      .catch((error) => {
        console.log(error, "error getting preloaded data");
        throw error;
      });
  };
  useEffect(() => {
    if (token) {
      loadState();
      console.log("token present");
    } else {
      setIsLoaded();
      console.log("no token");
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        position: "fixed",
        zIndex:"99"
      }}
    >
      <img
        src="https://order.qranbessa.net/assets/img/logo/qr_text.png"
        width={"300px"}
      />
    </div>
  );
}

export default Preloader;
