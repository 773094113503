import React, { useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import { Button } from "@mui/material";
import "./AddNewCompany.css";
import { useSelector } from "react-redux";
import {
  delete_company,
  fetch_companies,
} from "../../../Services/Admin/admin_services";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";

function DeleteCompany({ closePopup, companyName, companyId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const token = useSelector((state) => state.token);

  const handleDeletePressed = async () => {
    setIsLoading(true);

    try {
      const res = await delete_company(token, companyId);
      console.log(res, "deleted");
      setIsLoading(false);
      closePopup(false);
    } catch (err) {
      console.log(err, "delete company error");
      setIsLoading(false);
    //   closePopup(false);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else {
        setErrorMsg("Deleting Company Failed");
      }
      setIsError(true);
    }
  };
  return (
    <>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => setIsError(false)}
          isSuccess={false}
        ></ApiResult>
      )}
      <Popup title={"Careful!"}>
     
        <p>Are you sure you want to delete this company? </p>
        <br />
        <p> {`   -${companyName}`}</p>
        <div className="footer">
          <Button onClick={handleDeletePressed}>Delete</Button>
          <Button onClick={() => closePopup(true)}>
            <p>Close</p>
          </Button>
        </div>
      </Popup>
    </>
  );
}

export default DeleteCompany;
