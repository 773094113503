import { Branch } from "./Branch";

export class Company {
  private id: number;
  private name: string;
  private tin: string;
  private image: string;
  private opening_hour: string;
  private closing_hour: string;
  private primary_color: string;
  private secondary_color: string;
  private branches: Array<Branch>;

  constructor(
    id: number,
    name: string,
    tin: string,
    image: string,
    opening_hour: string,
    closing_hour: string,
    primary_color: string,
    secondary_color: string,
    branches: Array<Branch>
  ) {
    this.id = id;
    this.name = name;
    this.tin = tin;
    this.image = image;
    this.opening_hour = opening_hour;
    this.closing_hour = closing_hour;
    this.primary_color=primary_color;
    this.secondary_color=secondary_color;
    this.branches = branches;
  }
}
