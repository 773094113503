import { add_company_category} from 'Services/Manager/manager_services';
import Loading from 'Scenes/Widgets/Loading';
import ApiResult from 'Scenes/Widgets/ApiResult';
import React, { useState } from "react";
import Popup from "Components/Popups/Popup";
import CustomTextfield from "Scenes/Widgets/CustomTextfield";
import CustomForm from "Components/CustomForm/CustomForm";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import ShortNote from 'Components/ShortNote/ShortNote';
import { change_password } from 'Services/Common/common_services';

function EditUserProfile({ closePopup }) {
    const token = useSelector((state) => state.token);

    const [name, setName] = useState("");

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
  
    const [isLoading, setIsLoading] = useState(false);
  
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");
  
    const handelSubmit = async (e) => {
      e.preventDefault();
  
      try {
        setIsLoading(true);
  
        const param = {
          oldPassword,
          newPassword
        };
        const res = await change_password(token, param);
        setIsLoading(false);
        closePopup(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (err.response?.status === 409 || err.response?.status === 401) {
          setErrorMsg(err.response?.data.msg);
        } else {
          setErrorMsg("Couldn't add branch, try again.");
  
        }
        setIsError(true);
       
      }
    };  
  
    return (
      <div>
          {isLoading && 
          <Loading full={true}/>
          }
        {isError && (
          <ApiResult
            message={`${errorMsg}`}
            handleClose={() => {
              setIsError(false);
            }}
            isSuccess={false}
          />
        )}
        <Popup title={"Change Password"}>
          <CustomForm
            handleSubmit={(e) => handelSubmit(e)}
            
            button={
              <div>
                <Button type="submit"
               disabled={
                confirmNewPassword!==newPassword||
                String(confirmNewPassword).trim()===""||
                String(newPassword).trim()===""||
                String(oldPassword).trim()===""||
                String(newPassword).trim().length<8
            }
                >
                  <p>Update</p>
                </Button>
                <Button onClick={() => closePopup(true)}>
                  <p>Close</p>
                </Button>
              </div>
            }
          >
            <div className="span-2">
            <ShortNote text={"Passwords should be atleast 8 characters long"}>
              
            </ShortNote>  </div>
            <CustomTextfield
              label={"Old Password"}
              type={"password"}
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              placeholder={"old password"}
              required={true}
              span
            />
            <CustomTextfield
              label={"New Password"}
              type={"password"}

              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              placeholder={"new password"}
              required={true}
              span
            />
            <CustomTextfield
              label={"Confirm New Password"}
              type={"password"}

              onChange={(e) => setConfirmNewPassword(e.target.value)}
              value={confirmNewPassword}
              placeholder={"confirm new password"}
              required={true}
              span
            />
             
          </CustomForm>
        </Popup>
      </div>
    );
}
export default EditUserProfile