import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { useSelector } from "react-redux";
import UpdateTelergramIntegrationNumber from "./UpdateTelergramIntegrationNumber";
import { Button } from "@mui/material";
import ApiResult from "../Widgets/ApiResult";

function TelegramIntegration() {
  const [integratedInfo, SetintegratedInfo] = useState({});
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);
  const [isSucces, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false)
  const token = useSelector((state) => state.token);

  const getTelegramIntegration = async () => {
    await axios
      .get("/api/get/telegram-integration", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);
         
          SetintegratedInfo(res.data.msg)
       
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    
    getTelegramIntegration();
  }, []);

  useEffect(()=>{
    if(isSucces)
    {
    getTelegramIntegration();

    }
  },[isSucces])
  return (
    <div>
      {isSucces && (
        <ApiResult
          message={"Telegram Info Updated"}
          handleClose={() => {
            setIsSuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>)}
      {isUpdateFormVisible && (
        <UpdateTelergramIntegrationNumber
          close={(closed) => {
            if (closed) {
              setIsUpdateFormVisible(false);
            } else {
              setIsSuccess(true)
              setIsUpdateFormVisible(false);

            }
          }}
        />
      )}
      <div className="flex border rounded w-192 p-4 m-4 font-semibold flex-col">
        <div className="flex gap-6 justify-between w-full">
          <h1>Telegram Integrartion</h1>{" "}
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              // marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              setIsUpdateFormVisible(true);
            }}
          >
            Update Number
          </Button>
          ,
        </div>
        <div className="p-4 w-full justify-between">
          
          <p className="">
            Active Phone Number
          </p>
          <p className="font-normal">
            {integratedInfo.length ? integratedInfo[0].phone :"No Number Integrated"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TelegramIntegration;
