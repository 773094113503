import React, { useEffect, useState } from "react";
import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure.jsx";
import { Companies } from "../../../Models/Companies";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import AddNewCompany from "./AddNewCompany";
import ApiResult from "../../Widgets/ApiResult";
import { fetch_companies } from "../../../Services/Admin/admin_services";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import UpdateCompanyProfile from "./UpdateCompanyProfile";
import DeleteCompany from "./DeleteCompany";
function CompaniesPage() {

  const [companies, setCompanies] = useState([]);
  const token = useSelector((state) => state.token);
  const [companiesData, setCompaniesData] = useState(null);
  const [isAddFormVisisble, setIsAddFormVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdateCompanySuccess, setIsUpdateCompanySuccess] = useState(false);
  const [isDeleteCompanySuccess, setIsDeleteCompanySuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong");
  const [isReloading, setIsReLoading] = useState(false);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);

  const [updatingId, setUpdatingId] = useState("");
  const [deletingId, setDeletingId] = useState("");
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  const [offset, setOffset]=useState(0)

  //LOAD COMPANIES FROM STATE OR API
  const loadCompanies = async () => {
    try {
      const updated_companies = await fetch_companies(token,offset);
      const data = Companies(updated_companies, [
        () => {
          setIsUpdateFormVisible(true);
        },
        (id) => {
          setUpdatingId(id);
        },
        () => {
          setIsDeleteConfirmation(true);
        },
        (id) => {
          setDeletingId(id);
        },
      ]);
      setCompaniesData(data);
      setCompanies(updated_companies);
      console.log(data)
    } catch (e) {
      // setCompaniesData(e);
      setCompaniesData([]);
      if (e.response && e.response.request && e.response.request.statusText)
        setErrorMsg(e.response.request.statusText);
      else setErrorMsg("Can not connect!");
      setIsFail(true);
    }
  };
  // SET OFFSET
  const handleSetOffset=(up)=>{
    setIsReLoading(true)
    if(up) setOffset(offset+1)
    else setOffset(offset-1)
   
  }

  //RELOAD TABLE DATA FROM API
  const reloadCompanies = async () => {
    setCompaniesData(null);
    loadCompanies();
  };
  useEffect(() => {
    if (isReloading) {
      setCompaniesData(null);
      loadCompanies();
      setIsReLoading(false);
    }
  }, [isReloading]);

  // GET COMPANIES AT START UP
  useEffect(() => {
    loadCompanies();
  }, []);

  // ADD FORM CLOSE OR OPEN HANDLER
  const openForm = async (open, cancel) => {
    if (open) {
      setIsAddFormVisible(true);
    } else {
      setIsAddFormVisible(false);
      if (!cancel) {
        setIsSuccess(true);
      }
    }
  };
  // UPDATE FORM CLOSE OR OPEN HANDLER
  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setIsUpdateFormVisible(true);
    } else {
      setIsUpdateFormVisible(false);
      if (!cancel) {
        setIsUpdateCompanySuccess(true);
      }
    }
  };
  // DELETE CONFIRM CLOSE OR OPEN HANDLER
  const openDeleteConfirm = async (open, cancel) => {
    if (open) {
      setIsDeleteConfirmation(true);
    } else {
      setIsDeleteConfirmation(false);
      if (!cancel) {
        setIsDeleteCompanySuccess(true);
      }
    }
  };
 

  const openSuccessMsg = (open) => {
    if (open) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  };
  useEffect(() => {
    if (isSuccess || isDeleteCompanySuccess || isUpdateCompanySuccess) {
      setCompaniesData(null);
      loadCompanies();
    }
  }, [isSuccess, isDeleteCompanySuccess, isUpdateCompanySuccess]);

  return (
    <div>
      {isDeleteConfirmation&&
        <DeleteCompany
        closePopup={(close) => openDeleteConfirm(false, close)}
        companyId={deletingId}
        companyName={companies.filter((e) => e.id === deletingId)[0].name}
      />}
      {isUpdateFormVisible && (
        <UpdateCompanyProfile
          closePopup={(close) => openUpdateForm(false, close)}
          id={updatingId}
          name_p={companies.filter((e) => e.id === updatingId)[0].name}
          tin_p={companies.filter((e) => e.id === updatingId)[0].tin}
          open_p={companies.filter((e) => e.id === updatingId)[0].opening_hour}
          close_p={companies.filter((e) => e.id === updatingId)[0].closing_hour}
          primary_color={companies.filter((e) => e.id === updatingId)[0].primary_color}
          secondary_color={companies.filter((e) => e.id === updatingId)[0].secondary_color}
          image_fieldValues={
            companies.filter((e) => e.id === updatingId)[0].image
          }
          image_preview={companies.filter((e) => e.id === updatingId)[0].image}
        />
      )}
      {isSuccess && (
        <ApiResult
          message={`Company Added Succesfully`}
          handleClose={() => {
            openSuccessMsg(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isUpdateCompanySuccess && (
        <ApiResult
          message={`Company Updated Succesfully`}
          handleClose={() => {
            setIsUpdateCompanySuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isDeleteCompanySuccess && (
        <ApiResult
          message={`Company Deleted Succesfully`}
          handleClose={() => {
            setIsDeleteCompanySuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isFail && (
        <ApiResult
          message={errorMsg}
          handleClose={() => {
            setIsFail(false);
          }}
          isSuccess={false}
        ></ApiResult>
      )}
      {isAddFormVisisble && (
        <AddNewCompany closePopup={(close) => openForm(false, close)} />
      )}
      <MainBodyStructure

        handleSetOffset={(b)=>handleSetOffset(b)}
        offset={offset}
        title={"Companies"}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              openForm(true);
            }}
          >
            <AddIcon /> Add new company
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              reloadCompanies();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={companiesData}
        subtitle={"Currently registered companies."}
      />
    </div>
  );
}

export default CompaniesPage;

const prop_table = {
  header: [
    " ",
    "COMPANY NAME",
    "ADDRESS",
    "OPENING HOUR",
    "CLOSING HOUR",
    "ACTION",
  ],
  data: [
    {
      image: (
        <img
          className="table-image"
          src="https://res.cloudinary.com/dhe0yelid/image/upload/v1692110703/anbessa_8e7b499497.png"
          alt=""
        />
      ),
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
    {
      image: "URL",
      name: "QR",
      address: "Addis Ababa",
      opening: "12",
      closing: "12",
      action: "actions",
    },
  ],
};

