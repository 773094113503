import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import AuthContext from "../../Context/AuthProvider";
import { useDispatch } from "react-redux";
import { setBranches, setCompanies, setLogin } from "../../state";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import axios from "../../api/axios";
import Loading from "../Widgets/Loading";
import ApiResult from "../Widgets/ApiResult";
import { URLs } from "../../api/URLs";
import CustomTextfield from "../Widgets/CustomTextfield";
import { InputAdornment } from "@mui/material";
import { AccountCircle, Password, PasswordTwoTone, WifiPasswordOutlined } from "@mui/icons-material";

function Loginpage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error, try again!");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(
        URLs.LOGIN,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      //console.log(JSON.stringify(response));
      const accessToken = response?.data?.token;
      const user = response?.data?.user;
      const companies = response?.data?.company;
      // console.log(companies);

      //   setAuth({ user, accessToken });
      setPass("");
      setEmail("");
      if(response?.data?.user.role == 2){
        dispatch(
            setLogin({
              user: user,
              token:accessToken
            })
          );
        
      }
      else if(response?.data?.user.role == 1){
        dispatch(
            setLogin({
              user: user,
              token:accessToken

            })
          );
          dispatch(
            setBranches({
              branches: response?.data?.branches,
            })
          );
      }
      else if(response?.data?.user.role ===0){
        dispatch(
            setLogin({
              user: user,
              token:accessToken

            })
          );
          // dispatch(
          //   setBranches({
          //     branches: response?.data?.branches,
          //   })
          // );
      }
      else{

      }
      setIsLoading(false);
      if(user.role==2){
      navigate("/dash");

      }
      else if(user.role==1){
        navigate("/branches");

      } else if(user.role==0){
        navigate("/handle-orders");

      }else{
        navigate("/dash");

      }

    } catch (err) {
      setIsLoading(false);

      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrorMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrorMsg("Wrong password or username");
      } else {
        setErrorMsg("Login Failed");
      }
      // errRef.current.focus();
      setIsError(true);
      // setErrorMsg(err)
    }
  };
  const handleCloseErrorMsg = () => {
    setIsError(false);
  };
  return (
    <>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult message={`${errorMsg}`} handleClose={handleCloseErrorMsg}>
          <ReportProblemIcon />
        </ApiResult>
      )}
      {/* {isError && <ApiResult icon={ReportProblemIcon} message={"Something went wrong"}/>} */}
      {/* <div className="anbessa-it-logo">  */}
      {/* <img
          src="https://order.qranbessa.net/assets/img/logo/qr_text.png"
          alt="Anbessa IT logo"
        /> */}
      {/* </div> */}
      <div className="auth-form-container main">
        <img
          className="anbessa-it-logo-login"
          src="https://order.qranbessa.net/assets/img/logo/qr_text.png"
          alt="Anbessa IT logo"
        />
        <form className="login-form" onSubmit={handleSubmit}>
          {/* <label htmlFor="email">email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // type="email"
            placeholder="youremail@gmail.com"
            id="email"
            name="email"
          /> */}
          <CustomTextfield
            label={""}
            onChange={(e) => setEmail(e.target.value)}
            required={true}
            placeholder={"youremail@gmail.com"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          {/* <label htmlFor="password">password</label>
          <input
            value={password}
            onChange={(e) => setPass(e.target.value)}
            type="password"
            placeholder="********"
            id="password"
            name="password"
          /> */}
          <br/>
          <CustomTextfield
            label={""}
            onChange={(e) => setPass(e.target.value)}
            required={true}
            placeholder="********"
            type={"password"}
            
            InputProps={{
          
              startAdornment: (
                <InputAdornment position="start">
                  <Password/>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <button className="login-button" type="submit">Log In</button>
        </form>
        {/* <button className="link-btn">
          Don't have an account? Register here.
        </button> */}
      </div>
    </>
  );
}

export default Loginpage;
