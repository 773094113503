export class Branch{
    private id: number
    private name: String;
    private address: String;
    private status: number;

    constructor(
        id:number,
        name:String,
        address: String,
        status: number
    ){
        this.id = id;
        this.name = name;
        this.address = address;
        this.status = status;
    }
}