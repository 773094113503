import React, { useLayoutEffect, useState } from "react";
import "./Common/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faUser,
  faCalendar,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import NavbarMenuButton from "./Common/NavbarMenuButton.js";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AccountHeader from "./Common/AccountHeader";
import { admin_navbar_links, cashier_navbar_links, manager_navbar_links } from "../../Constants/NavBarItems";
import { useSelector } from "react-redux";

function Navbar() {
  const location = useLocation();
  const dashIcon = <FontAwesomeIcon icon={faChartLine} />;
  const patientIcon = <FontAwesomeIcon icon={faUser} />;
  const appointmentIcon = <FontAwesomeIcon icon={faCalendar} />;
  const settingIcon = <FontAwesomeIcon icon={faGear} />;
  const role = useSelector((state) => state.user.role);

  const nav_links = [
    {
      title: "Dashboard",
      icon: dashIcon,
      path: "/dash",
    },
    {
      title: "Patients",
      icon: patientIcon,
      path: "/patients",
    },
    {
      title: "Appointments",
      icon: appointmentIcon,
      path: "/appointments",
    },
    {
      title: "Setting",
      icon: settingIcon,
      path: "/setting",
    },
  ];
  const [navLinks, setNavLinks] = useState([])
  useLayoutEffect(()=>{
    if(role==2)setNavLinks(admin_navbar_links)
    else if(role==1)setNavLinks(manager_navbar_links)
    else if(role===0)setNavLinks(cashier_navbar_links)
  },[])
  return (
    <div className="nav-bar-div">
      <div className="anbessa-it-logo"> 
        <img
          src="https://order.qranbessa.net/assets/img/logo/qr_text.png"
          alt="Anbessa IT logo"
        />
      </div>
      {/* <hr className="twoOnePadding" /> */}

      <ul className="menu-buttons">
        {navLinks.map((e) => {
          return (
            <li>
              <Link to={e.path} className="no-style-link hovershadow">
                <NavbarMenuButton
                  title={e.title}
                  Icon={e.Icon}
                  color={
                    location.pathname === e.path ? "rgb(62, 62, 216)" : "gray"
                  }
                  backgroundColor={location.pathname === e.path && "#c3def0"}
                  border={location.pathname === e.path && "5px solid rgb(62, 62, 216)"}
                />
              </Link>
            </li>
          );
        })}
      </ul>

      <AccountHeader />
    </div>
  );
}

export default Navbar;
